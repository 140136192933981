import { makeResource } from '@codium/codium-vue'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('screens/logs', {
  namespaced: true,

  resource () {
    return {
      id: null,
      log_time: null,
      amps: null,
      kwh: null
    }
  }
})
