import { makeResource } from '@codium/codium-vue'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('screens/consumptionLogs', {
  namespaced: true,

  resource () {
    return {
      period: null,
      average_power_consumption_kwh: null,
      average_power_consumption_amps: null
    }
  }
})
