import { applyRouteMeta } from '@codium/codium-vue'
import toNumber from 'lodash/toNumber'

export default applyRouteMeta({
  template: 'app',
  requiresAuth: true
}, [
  {
    path: '/screens',
    name: 'screens',
    meta: {
      can: 'read screens'
    },
    component: () => import(/* webpackChunkName: "screens" */ '@/views/screens/ScreensList.js')
  },
  {
    path: '/screens/:screenId',
    component: () => import(/* webpackChunkName: "screens" */ '@/views/screens/ScreensSingle'),
    props: ({ params }) => ({ screenId: toNumber(params.screenId) }),
    children: [
      {
        path: '/screens/:screenId',
        name: 'screens.single',
        meta: {
          can: 'read screens'
        },
        component: () => import(/* webpackChunkName: "screens" */ '@/views/screens/ScreensSingleOverview')
      },
      {
        path: 'image-history',
        name: 'screens.single.image-history',
        meta: {
          can: 'read screens'
        },
        component: () => import(/* webpackChunkName: "screens" */ '@/views/screens/image-history/ImageHistoryList')
      },
      {
        path: 'brightness-logs',
        name: 'screens.single.brightness-logs',
        meta: {
          can: 'read screens'
        },
        component: () => import(/* webpackChunkName: "screens" */ '@/views/screens/brightness-logs/BrightnessLogsList')
      },
      {
        path: 'faults',
        name: 'screens.single.faults',
        meta: {
          can: 'read screens'
        },
        component: () => import(/* webpackChunkName: "screens" */ '@/views/screens/faults/FaultsList')
      },
      {
        path: 'power-consumption',
        name: 'screens.single.power-consumption',
        meta: {
          can: 'read screens'
        },
        component: () => import(/* webpackChunkName: "screens" */ '@/views/screens/power-consumption/PowerConsumption')
      },
    ]
  }
])
