import { makeResource, mapped } from '@codium/codium-vue'
import { storeModule as brightnessLogs } from './screens/brightness-logs'
import { storeModule as faults } from './screens/faults'
import { storeModule as images } from './screens/images'
import { storeModule as consumptionLogs } from './screens/consumption-logs'
import { storeModule as logs } from './screens/logs'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('screens', {
  storeModule: {
    modules: {
      brightnessLogs,
      faults,
      images,
      consumptionLogs,
      logs
    },
  },

  resource () {
    return {
      id: mapped(null),
      name: mapped(null),
      address: mapped(null),
      longitude: mapped(null),
      latitude: mapped(null),
      status: mapped(null),
      has_camera: mapped(null),
      channel: mapped(null),
      auto_brightness_enabled: mapped(null),
      brightness: mapped(null),
      max_brightness: mapped(null),
      environment_brightness: mapped(null),
      tags: mapped(null),
      media_owner: mapped(null),
      media_owner_id: mapped(null),
      has_backup_power_supplies: mapped(false),
      start_time: mapped(null),
      end_time: mapped(null),
      timezone: mapped(null),
      device_id: mapped(null)
    }
  }
})
